// firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Firebase configuration
 
const firebaseConfig = {
  apiKey: "AIzaSyC2i1pQjYSQj5FmvKaBWMN6hsHB5W5g1G8",
  authDomain: "liontouchwebpark-a5ef9.firebaseapp.com",
  databaseURL: "https://liontouchwebpark-a5ef9-default-rtdb.firebaseio.com",
  projectId: "liontouchwebpark-a5ef9",
  storageBucket: "liontouchwebpark-a5ef9.appspot.com",
  messagingSenderId: "1029426734677",
  appId: "1:1029426734677:web:757b55874f3a47c30a96dc",
  measurementId: "G-LG1ZQ2PDQP"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore and Storage
const db = getFirestore(app);
const storage = getStorage(app);

export { db, storage };
