// import React from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { db } from "./firebaseConfig";
import { collection, addDoc } from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdClose } from "react-icons/md"; // Importing a close icon from react-icons
import React, { useState } from "react";

export default function Formpage() {
  const [formData, setFormData] = useState({
    name: "",
    gender: "",
    currentBusiness: "",
    mobileNumber: "",
    city: "",
  });

  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false);

  const validate = () => {
    const errors = {};

    if (!formData.name) errors.name = "Name is required";
    if (!formData.gender) errors.gender = "Gender is required";
    if (!formData.currentBusiness)
      errors.currentBusiness = "Current Business is required";
    if (!formData.mobileNumber) {
      errors.mobileNumber = "Mobile Number is required";
    } else if (!/^\d{10}$/.test(formData.mobileNumber)) {
      errors.mobileNumber = "Invalid Mobile Number. Must be 10 digits.";
    }
    if (!formData.city) errors.city = "City is required";

    return errors;
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setSuccess(false);
    } else {
      try {
        await addDoc(collection(db, "registrations"), formData);
        toast.success("Thankyou for register", {
          position: "top-right",
          closeButton: <MdClose />, // Custom close icon
        });
        setSuccess(true);
        setFormData({
          name: "",
          gender: "",
          currentBusiness: "",
          mobileNumber: "",
          city: "",
        });
        setErrors({});
      } catch (error) {
        console.error("Error adding document: ", error);
        toast.error("Error in registration. Please try again.", {
          position: "top-right",
        });
        setSuccess(false);
      }
    }
  };

  return (
    <Container className="form-container" style={{ width: "100%" }}>
      <Row className="justify-content-center">
        <Col lg={12} md={12} sm={12}>
          <div className="form-card">
            <h2 className="form-title">Part Time Business .....</h2>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formName">
                <Form.Label className="text-light">Name</Form.Label>
                <Form.Control
                  className="text-light"
                  type="text"
                  name="name"
                  placeholder="Enter your name"
                  value={formData.name}
                  onChange={handleChange}
                  isInvalid={!!errors.name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formGender">
                <Form.Label className="text-light mt-2">Gender</Form.Label>
                <Form.Control
                  className="text-light"
                  as="select"
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                  isInvalid={!!errors.gender}
                >
                  <option className="text-dark" value="">Select gender</option>
                  <option className="text-dark" value="Male">Male</option>
                  <option className="text-dark" value="Female">Female</option>
                  
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.gender}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formCurrentBusiness">
                <Form.Label className="text-light mt-2">Current Business</Form.Label>
                <Form.Control
                  className="text-light"
                  type="text"
                  name="currentBusiness"
                  placeholder="Enter your current business"
                  value={formData.currentBusiness}
                  onChange={handleChange}
                  isInvalid={!!errors.currentBusiness}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.currentBusiness}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formMobileNumber">
                <Form.Label className="text-light mt-2">Mobile Number</Form.Label>
                <Form.Control
                  className="text-light"
                  type="text"
                  name="mobileNumber"
                  placeholder="Enter your mobile number"
                  value={formData.mobileNumber}
                  onChange={handleChange}
                  isInvalid={!!errors.mobileNumber}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.mobileNumber}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formCity">
                <Form.Label className="text-light mt-2">City</Form.Label>
                <Form.Control
                  className="text-light"
                  type="text"
                  name="city"
                  placeholder="Enter your city"
                  value={formData.city}
                  onChange={handleChange}
                  isInvalid={!!errors.city}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.city}
                </Form.Control.Feedback>
              </Form.Group>

              <Button variant="primary" type="submit" className="w-100 mt-4">
                Register
              </Button>
            </Form>
          </div>
        </Col>
      </Row>
      <ToastContainer />
    </Container>
  );
}
