import React from "react";
import { Link } from "react-router-dom";

export default function Homepage() {
  return (
    <div className="homepage-container">
      <div className="background-img  d-flex justify-content-center">
        <h4 className="pt-5 w-lg-50 p-2"> વ્યવસાય  ક્ષેત્રે  હાલમાં  ચાલી  રહેલી  હાલાકી  (આર્થિક મંદી) ના કારણે ઘણા લોકો પોતાના કામ થી વંચિત છે અને ઘર ચલાવવું મુશ્કેલ બની રહ્યું છે.. <br/>

          જો આપ અથવા આપની આસપાસ રહેલ કોઈ પણ વ્યક્તિ કામ કે વ્યવસાય ની આવી આર્થિક સંકડામણ માં ફસાયેલ હોય તો અમારો કોન્ટેક્ટ કરો.. રોજગાર આપવા માટે અમારા થી થતી તમામ મદદ કરીશું.. <br/>
          (દરેક જરૂરિયાત મંદ ને મોકલો...)
          <br/>
          👉🏻વિચારધારા👇🏻 <br/>

          * 2 વર્ષ માં આર્થિક આઝાદીનું પ્લેટફોર્મ ઉભુ કરવું.. <br/>

          * આવનારી પેઢી ને વધુ માં વધુ રોજગારી આપવી..

          * આર્થિક આઝાદી સાથે દરેક વ્યક્તિ ને સ્વ-વિકાસ ની તક અને પોતાનામાં રહેલી પ્રતિભા જાગૃત કરવી..
        </h4>
        <Link to="Formpage" className="background-button mt-5">
          Click Me
        </Link> 
      </div>
    </div>
  );
}
