import React from "react";
import Homepage from "./Homepage";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import Formpage from "./FormPage";
import "./firebaseConfig";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/Formpage" element={<Formpage />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
